import React from "react";
import OnlyMediaView from "./OnlyMedia.view";

const OnlyMediaController = ({
  section_id = "",
  Media,
  height,
  width,
  borderRadius,
  alignment = "center",
  ctaLink = "",
  ctaText = "",
  heading = "",
  styleOverride = null,
  enlargeImageOnClick = false,
  showOn = [],
  imageLink = "",
  imgQuality = 50,
}) => {
  const aspectRatio = Media?.height / Media?.width;

  return (
    <OnlyMediaView
      imgQuality={imgQuality}
      imageLink={imageLink}
      showOn={showOn}
      section_id={section_id}
      styleOverride={styleOverride}
      enlargeImageOnClick={enlargeImageOnClick}
      url={Media?.url}
      Media={Media}
      alt={Media?.name}
      mime={Media?.mime}
      borderRadius={borderRadius}
      height={height ? height : aspectRatio * width}
      width={width ? width : height / aspectRatio}
      alignment={alignment}
      ctaText={ctaText}
      ctaLink={ctaLink}
      heading={heading}
    />
  );
};

export default OnlyMediaController;
