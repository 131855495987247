import React from "react";
import styles from "./OnlyMedia.module.css";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import { alignmentMapping } from "@/staticData/constant";
import MediaRenderer from "@/mvcComponents/Commons/MediaRenderer/MediaRenderer.view";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const OnlyMediaView = ({
  section_id,
  url,
  height,
  Media,
  width,
  alt,
  alignment,
  ctaLink,
  ctaText,
  heading,
  styleOverride,
  borderRadius,
  enlargeImageOnClick,
  showOn,
  imageLink,
  imgQuality
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section id={section_id}>
        <div
          view-analytics={[UIElements.CARD, `${section_id}-only-media`]}
          className={`${styles.media} ${styles[alignment]}`}
          style={{
            textAlign: alignment,
            alignItems: alignmentMapping[alignment],
          }}
        >
          {heading && <h2 className={styles.title}>{heading}</h2>}
          <MediaRenderer
            image={Media}
            imageLink={imageLink}
            url={url}
            height={height}
            width={width}
            imgQuality={imgQuality}
            styleOverride={styleOverride}
            alt={alt}
            borderRadius={borderRadius}
            enlargeImageOnClick={enlargeImageOnClick}
          />
          {ctaLink && ctaText && (
            <ConditionLinkView
              link={ctaLink}
              section_id={section_id}
              analytics="media"
            >
              <div className={styles.cta}>{ctaText}</div>
            </ConditionLinkView>
          )}
        </div>
      </section>
    </UseComponentVisibility>
  );
};

export default OnlyMediaView;
